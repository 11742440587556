<script setup lang="ts">

import type { CmsElementMetrics } from "~/types/cmsElementTypes";
import { useCmsElementConfig } from "#imports";

const { apiClient } = useShopwareContext();

const metricsData = ref([])
const metricsLoading = ref(false)

const props = defineProps<{
  content: CmsElementMetrics;
}>();

const { getConfigValue } = useCmsElementConfig(props.content);
const customMetricValue = getConfigValue('customMetricValue');
const customMetricLabel = getConfigValue('customMetricLabel');

const fetchMetrics = async () => {
  metricsLoading.value = true;
  const result = await apiClient.invoke("getMetrics get /metrics", {}).then((data) => {return data.data});
  metricsData.value = result.data;
  metricsLoading.value = false;
}
fetchMetrics()

</script>
<template>
  <v-row>
    <v-col v-if="!metricsLoading" v-for="(metric, index) in metricsData" :key="index" cols="6" md="3">
      <div class="metric">
        <span class="metric__value text-h3 text-sm-h2 d-block mb-2">
          {{ metric.value }}
        </span>
        <span class="metric__name d-block">
          {{ metric.name }}
        </span>
      </div>
    </v-col>
    <v-col v-if="customMetricLabel && customMetricValue && !metricsLoading" cols="6" md="3">
      <div class="metric">
        <span class="metric__value text-h3 text-sm-h2 d-block mb-2">
          {{ customMetricValue }}
        </span>
        <span class="metric__name d-block">
          {{ customMetricLabel }}
        </span>
      </div>
    </v-col>
    <v-col v-if="metricsLoading" v-for="(index) in 4" :key="index" cols="6" md="3">
      <v-skeleton-loader type="heading, text" />
    </v-col>
  </v-row>

</template>
<style lang="scss" scoped>
  .metric {
    &__value {
      font-weight: 700;
    }

    &__name {
      font-size: 1.125rem;
    }
  }

  :deep(.v-skeleton-loader) {
      background: transparent;
  }

</style>
